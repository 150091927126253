<template>
  <div class="add-new-section-area">
    <h5 class="add-section-text">add a new section</h5>
  </div>
</template>

<script>
export default {
  name: "AddSectionBigButton",
};
</script>

<style lang="scss" scoped>
.add-new-section-area {
  display: flex;
  height: $content-width-lg/5;
  margin: $item-margin;
  border: dashed $grey-300 3px;
  border-radius: $border-radius;
  justify-content: center;
  align-items: center;
  color: $grey-300;
  font-size: 1.5em;
  font-weight: 400;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    border-color: $primary-300;
    color: $primary-300;
  }
}
</style>
