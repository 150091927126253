<template>
  <div>
    <h4 class="page-title">Show off a thing you can do!</h4>
    <post-title-input
      ref="titleBox"
      @titleChange="(ev) => (title = ev)"
      @descriptionChange="(ev) => (description = ev)"
      @tagsChange="(ev) => (tags = ev)"></post-title-input>
    <post-submit-section
      v-for="(section, i) in sections"
      :key="i"
      :ref="'section' + i"
      @newwords="(e) => updateWords(i, e)"
      @newimage="(e) => updateImage(i, e)"
      @newlink="(e) => updateLink(i, e)"></post-submit-section>
    <add-section-big-button
      @click="sections.push({ image: '', text: '' })"></add-section-big-button>
    <div class="extra-section">
      <div class="extra-questions">
        <p class="project-status-text text">
          What is the status of this project?
        </p>
        <select
          name="project-status"
          id=""
          v-model="projectStatus"
          class="project-status-select">
          <option value="p" class="text-purple">Planning</option>
          <option value="i" class="text-primary">In Progress</option>
          <option value="s" class="text-warning">Stalled</option>
          <option value="c" class="text-yellow">Completed</option>
        </select>
        <p class="text backdate-text">When did you start this project?</p>
        <input type="date" v-model="startingDate" class="backdate-input" />
        <p v-if="projectStatus == 'c'" class="text backdate-text">
          When did you finish this project?
        </p>
        <input
          v-if="projectStatus == 'c'"
          type="date"
          v-model="completionDate"
          class="backdate-input" />
      </div>
      <div
        class="main-image"
        v-if="sections.filter((x) => x.image !== '').length">
        <p class="text main-image-text">Which image to use as the thumbnail?</p>
        <div class="image-container">
          <div
            v-for="(section, i) of sections"
            :key="i"
            class="small-image-container">
            <img
              v-if="section.image"
              :src="imageSources[i]"
              alt="hi"
              class="small-image"
              :class="[mainImage === i ? 'clicked' : '']"
              @click="mainImage = i"
              :style="section.image == '' ? 'display: none;' : ''" />
          </div>
        </div>
        <!-- <div class="make-private-box">
          <label for="make-private-input" class="text"
            >make this a private post?</label
          >
          <input id="make-private-input" type="checkbox" v-model="isPrivate" />
        </div> -->
      </div>
    </div>
    <div class="buttons-container-row">
      <button
        class="button button-primary-filled"
        :class="buttonDisabled ? 'disabled' : ''"
        @click="submitForm()">
        post thing
      </button>
      <button
        class="button button-primary"
        @click="
          $notify({
            title: 'sorry',
            body: 'this isnt finished yet',
            type: 'warning',
          })
        ">
        preview post
      </button>
    </div>
  </div>
</template>

<script>
import PostSubmitSection from "./PostSubmitSection.vue";
import PostTitleInput from "./PostTitleInput.vue";
import axios from "axios";
import AddSectionBigButton from "./AddSectionBigButton.vue";
import { imageMixin } from "@/mixins/imageMixin.js";
export default {
  components: { PostSubmitSection, PostTitleInput, AddSectionBigButton },
  name: "PostSubmitForm",
  mixins: [imageMixin],
  data() {
    return {
      sections: [{ image: "", text: "" }],
      title: "",
      description: "",
      tags: "",
      projectStatus: "i",
      startingDate: new Date().toISOString().split("T")[0],
      completionDate: new Date().toISOString().split("T")[0],
      imageSources: [],
      mainImage: 0,
      isPrivate: false,
      buttonDisabled: false,
    };
  },
  methods: {
    updateWords(i, e) {
      this.sections[i].text = e;
    },
    updateImage(i, e) {
      this.sections[i].image = e;
      this.imageSources[i] = this.loadingImageSrc;
      this.getSrc(e).then((url) => {
        this.imageSources[i] = url;
      });
    },
    updateLink(i, e) {
      this.sections[i].image = "url:" + e;
      this.imageSources[i] = this.loadingImageSrc;
      this.getSrc("url:" + e).then((url) => {
        this.imageSources[i] = url;
      });
    },
    submitForm() {
      this.buttonDisabled = true;
      this.$store.commit("setLoading", true);
      let data = new FormData();
      data.set("title", this.title);
      data.set("description", this.description);
      data.set(
        "tags",
        this.tags.split(" ").filter((x) => x !== " " && x !== "")
      );
      data.set("owner", this.$store.state.userData.username);
      data.set("sections", this.sections.length);
      data.set("main_image", this.mainImage);
      data.set("project_status", this.projectStatus);
      data.set("is_public", !this.isPrivate);
      data.set("start_date", this.startingDate);
      if (this.projectStatus === "c") {
        data.set("completion_date", this.completionDate);
      }
      this.sections.forEach((section, i) => {
        if (section.image !== "") {
          data.set("image_" + i, section.image);
        }
        if (section.text !== "") {
          data.set("content_" + i, section.text);
        }
      });
      axios
        .post("/api/v1/posts/submit/", data)
        .then((res) => {
          if (res.status == 201) {
            this.$router.push("/thing/" + res.data.id + "/" + res.data.slug);
          }

          this.$store.commit("setLoading", false);
        })
        .catch((err) => {
          if (!err.response || err.response.status == 500) {
            this.$notify({
              title: "sorry",
              body: "there seems to be an issue with the servers, please try again after a few minutes.",
              type: "danger",
            });
            this.$store.commit("setLoading", false);
            this.buttonDisabled = false;
            return;
          }
          Object.keys(err.response.data).forEach((key) => {
            switch (key) {
              case "title":
                this.$refs.titleBox.$refs.titleError.addErrors(
                  err.response.data[key]
                );
                break;
              case "slug":
                break;
              case "tags":
                this.$refs.titleBox.$refs.tagsError.addErrors(
                  err.response.data[key]
                );
                break;
              case "description":
                this.$refs.titleBox.$refs.descriptionError.addErrors(
                  err.response.data[key]
                );
                break;
              case "content":
                this.$refs[
                  "section" + (this.sections.length - 1)
                ][0].$refs.error.addError(
                  "One of your sections has the following issue:"
                );
                this.$refs[
                  "section" + (this.sections.length - 1)
                ][0].$refs.error.addErrors(err.response.data[key]);
            }
          });
          this.buttonDisabled = false;
          this.$store.commit("setLoading", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  text-transform: uppercase;
  color: $grey-600;
  font-weight: 400;
  font-size: 1.5rem;
  margin: $item-margin;
}

.extra-section {
  width: inherit;
  background-color: $grey-500;
  height: fit-content;
  display: flex;
  border-radius: $border-radius;
  margin: $item-margin;
  padding: 0.5rem;
  justify-content: space-between;
}
.image-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.2rem;
  padding: $item-margin;
  column-gap: 0.2rem;
}
.small-image-container {
  width: 4rem;
  object-fit: contain;
}
.text {
  font-size: 0.8rem;
  color: $white;
}
.small-image {
  border-radius: $border-radius;
  cursor: pointer;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  &:hover {
    outline: $info-500 solid 2px;
  }
}
.clicked {
  outline: $yellow solid 2px !important;
}
</style>
