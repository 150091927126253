<template>
  <div class="page-make-post">
    <post-submit-form></post-submit-form>
  </div>
</template>

<script>
import PostSubmitForm from "@/components/posts/PostSubmitForm.vue";
export default {
  name: "MakePost",
  methods: {},
  components: {
    PostSubmitForm,
  },
  mounted() {
    document.title = "post a thing you can do | icandoathing";
  },
};
</script>

<style lang="scss" scoped>
.page-make-post {
  width: 100%;
}
</style>
